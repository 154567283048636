import { API_METHOD } from '@/utils/constants';
import { apiFetch } from '@/utils/api';
import type { iUser } from '@/types/apiModels';

// Get user's data
let apiGetUserController: AbortController;
export const apiGetUser = async () => {
  apiGetUserController?.abort?.();
  apiGetUserController = new AbortController();

  const res: iUser = await apiFetch('users/me', {
    method: API_METHOD.GET,
    signal: apiGetUserController.signal,
    customOptions: {
      handleLocalError: true,
    },
  });

  return res;
};

// Update user's data
export const apiUpdateUser = async (body: Partial<iUser>) => {
  const res: iUser = await apiFetch('users/me', {
    method: API_METHOD.PATCH,
    body,
  });

  return res;
};
