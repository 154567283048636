import { ANALYTIC_SERVICE, API_METHOD } from '@/utils/constants';
import { apiFetch } from '@/utils/api';

/**
 * Function analytic track
 * @param {ANALYTIC_SERVICE.UMAMI | ANALYTIC_SERVICE.API} service - service name. Possible values - umami, api
 * @param {string} event_name - event name
 * @param {object} data - data to analytics send
 */
export async function sendAnatylics(service, event_name, data) {
  switch (service) {
    case ANALYTIC_SERVICE.UMAMI:
      if (typeof umami !== 'undefined') {
        // eslint-disable-next-line no-undef
        umami.track(event_name, data);
      }
      break;
    case ANALYTIC_SERVICE.API:
      apiFetch('analytics', {
        method: API_METHOD.POST,
        body: {
          event_type: event_name,
          data,
        },
      });
      break;
    default:
      break;
  }
}
