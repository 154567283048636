export const isTouchSupportDevice = () => {
  return (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch) ||
    window.matchMedia('(pointer: coarse)').matches
  );
};

export const isAndroidPlatformDevice = () => {
  const platform = navigator?.userAgentData?.platform || '';
  return platform.includes('Linux') || platform.includes('Android');
};
