export const saveIfUnique = (key, onSave) => {
  if (localStorage.getItem(key) === null) {
    localStorage.setItem(key, true);
    onSave?.();
  }
};

export const setLocationToExitFromReader = (book_slug, url) => {
  localStorage.setItem(`exit_reader_location_${book_slug}`, url);
};

export const getLocationToExitFromReader = (book_slug) => {
  return localStorage.getItem(`exit_reader_location_${book_slug}`) || '/';
};

export const removeAllExitFromReaderLocations = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('exit_reader_location_')) {
      localStorage.removeItem(key);
    }
  });
};
