import { defineStore } from 'pinia';
import { GrowthBook } from '@growthbook/growthbook';
import {
  FEATURE_FLAG,
  HIGHLIGHTED_DEFAULT_TEXT_COLOR,
} from '@/utils/constants';
import { isValidHexColor } from '@/utils/stringUtils';

export const useFeatureFlagStore = defineStore('feature-flag', {
  state: () => ({
    growthbook: shallowRef<GrowthBook | null>(null),
  }),

  actions: {
    async initStore() {
      const config = useRuntimeConfig();

      this.growthbook = new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: config.public.growthBookKey,
        enableDevMode: process.env.NODE_ENV === 'development',
        subscribeToChanges: true,
        trackingCallback: (experiment, result) => {
          console.log('Viewed Experiment', {
            experimentId: experiment.key,
            variationId: result.key,
          });
        },
      });

      await this.growthbook.init();
    },

    setDefaultHighlightColor() {
      const configColor = this.growthbook?.getFeatureValue(
        FEATURE_FLAG.highlightColor,
        HIGHLIGHTED_DEFAULT_TEXT_COLOR
      );
      const color = isValidHexColor(configColor)
        ? configColor
        : HIGHLIGHTED_DEFAULT_TEXT_COLOR;

      if (color) {
        document.documentElement.style.setProperty(
          '--reader-highlighted-text',
          color
        );
      }
    },
  },
  getters: {
    isSignUpAvailable: (state) =>
      state.growthbook && state.growthbook.isOn(FEATURE_FLAG.signUp),
    isPurchaseAvailable: (state) =>
      state.growthbook && state.growthbook.isOn(FEATURE_FLAG.purchase),
    highlightColor: (state) => {
      const configColor = state.growthbook?.getFeatureValue(
        FEATURE_FLAG.highlightColor,
        HIGHLIGHTED_DEFAULT_TEXT_COLOR
      );
      return isValidHexColor(configColor)
        ? configColor
        : HIGHLIGHTED_DEFAULT_TEXT_COLOR;
    },
    isApiResponseCachingEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.apiResponseCaching),
    closedBetaMessageTitle: (state) =>
      state.growthbook?.getFeatureValue(
        FEATURE_FLAG.closedBetaMessageTitle,
        'Thank you for your interest in our product.'
      ),
    closedBetaMessageText: (state) =>
      state.growthbook?.getFeatureValue(
        FEATURE_FLAG.closedBetaMessageText,
        'We are currently in a closed-beta. You can apply to be a part of it by filling out an application. If you think this is a mistake, please confirm the email address you used {{ user.email }} against the one you signed up for the beta with.'
      ),
    closedBetaLink: (state) =>
      state.growthbook?.getFeatureValue(FEATURE_FLAG.closedBetaLink, ''),
    isLayoutModeSettingsEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.isLayoutModeSettingsEnabled),
    chatWithRebinderTooltipText: (state) =>
      state.growthbook?.getFeatureValue(
        FEATURE_FLAG.chat_with_rebinder_tooltip_text,
        'Chat with Rebinder'
      ),
    chatWithAssistantTooltipText: (state) =>
      state.growthbook?.getFeatureValue(
        FEATURE_FLAG.chat_with_assistant_tooltip_text,
        'Chat with Assistant'
      ),
    isFullScreenModeEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.isFullScreenModeEnabled),
    isSubscriptionEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.isSubscriptionEnabled),
    isReflectionModeEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.isReflectionModeEnabled),
    isRateResponseEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.isRateResponseEnabled),
    isReflectionSettingsEnabled: (state) =>
      state.growthbook &&
      state.growthbook.isOn(FEATURE_FLAG.isReflectionSettingsEnabled),
    isArticlesEnabled: (state) =>
      state.growthbook && state.growthbook.isOn(FEATURE_FLAG.enable_articles),
  },
});
