import { defineStore } from 'pinia';
import { jwtDecode } from 'jwt-decode';
import { apiGetUser, apiUpdateUser } from '@/api/users';
import { updateRootCssReaderFont } from '@/utils/dom';
import type { iUser } from '@/types/apiModels';
import type { Nullable } from '@/types/utility';

interface iStoreState {
  user: Nullable<iUser>;
  token: Nullable<string>;
  tokenExpirationTime: Nullable<number>;
  isReady: boolean;
  controller: Nullable<AbortController>;
}

export const useUserStore = defineStore('user', {
  state: (): iStoreState => {
    return {
      user: null,
      token: null,
      tokenExpirationTime: null,
      isReady: false,
      controller: null,
    };
  },

  actions: {
    async getUser() {
      const data = await apiGetUser();

      this.user = data || null;
      this.isReady = true;

      updateRootCssReaderFont(data.reader_settings.font);
      return data;
    },

    saveTokenData(token: string) {
      if (!token) return;
      const decodedToken = jwtDecode(token);
      this.token = token;
      if (decodedToken.exp) {
        this.tokenExpirationTime = decodedToken.exp * 1000;
      }
    },

    cleanupUserData() {
      this.user = null;
      this.token = null;
      this.tokenExpirationTime = null;
    },

    async updateUser(payload: Partial<iUser>) {
      const data: iUser = await apiUpdateUser(payload);

      this.user = data || this.user;
    },

    isTokenExpired() {
      if (!this.tokenExpirationTime) return true;
      // Token is considered expired 5 minutes before its actual expiration time
      return new Date(this.tokenExpirationTime - 5 * 60 * 1000) < new Date();
    },

    updateReaderSettings(settings: Partial<iUser['reader_settings']>) {
      if (!this.user?.reader_settings) return;

      this.user = {
        ...this.user,
        reader_settings: {
          ...this.user.reader_settings,
          ...settings,
        },
      };

      this.updateUser({
        reader_settings: this.user.reader_settings,
      });
    },
  },

  getters: {
    isAuthenticated: (state) => !!state.user,
    readerSettings: (state) => state.user?.reader_settings || {},
    readerSettingsSelectedTheme: (state) => state.user?.reader_settings?.theme,
    readerSettingsSelectedFont: (state) => state.user?.reader_settings?.font,
    readerSettingsSelectedFontSize: (state) =>
      state.user?.reader_settings?.font_size,
    readerSettingsSelectedPageLayout: (state) =>
      state.user?.reader_settings?.page_layout,
    readerSettingsSelectedNavigation: (state) =>
      state.user?.reader_settings?.navigation,
    isSubscriptionExpired: (state) =>
      !state.user?.is_subscribed && state.user?.subscription_expires_at,
    hasActiveOrExpiredSubscription: (state) =>
      state.user?.is_subscribed || state.user?.subscription_expires_at,
    isSubscriptionActive: (state) => state.user?.is_subscribed,
    isTrialSubscription: (state) => state.user?.is_trial_subscription,
  },
});
