<template>
  <el-footer class="footer">
    <el-container>
      <el-row class="footer_row">
        <el-col class="footer_col" :xs="24" :sm="12" :md="7" :lg="9">
          <MainLogo class="footer_logo">
            <div class="footer_logo_svg">
              <LogoIcon color="white" />
            </div>
          </MainLogo>
          <p class="body-1 footer_text">
            Transform the Classics into AI-powered Conversation.
          </p>
          <div class="footer_info tablet-info">
            <a
              href="https://www.rebind.ai/terms-conditions"
              target="_blank"
              class="footer_info-link"
            >
              Terms & Conditions
            </a>
            <a
              href="https://www.rebind.ai/policy"
              target="_blank"
              class="footer_info-link"
            >
              Privacy Policy
            </a>
          </div>
          <p class="body-1 footer_copy tablet-copy">
            © {{ new Date().getFullYear() }} Rebind | Version {{ appVersion }}
          </p>
        </el-col>
        <el-col class="footer_desktopWrap" :xs="0" :md="10" :lg="9">
          <nav class="footer_nav desktop-nav">
            <ul>
              <li>
                <span class="footer_link" @click="catalogueRedirect"
                  >Catalogue</span
                >
              </li>
              <li v-if="userStore.isAuthenticated">
                <span class="footer_link" @click="myBooksRedirect"
                  >My Books</span
                >
              </li>
            </ul>
          </nav></el-col
        >
        <el-col class="footer_col" :xs="24" :sm="12" :md="7" :lg="6">
          <nav class="footer_nav tablet-nav">
            <ul>
              <li>
                <span class="footer_link" @click="catalogueRedirect"
                  >Catalogue</span
                >
              </li>
              <li v-if="userStore.isAuthenticated">
                <span class="footer_link" @click="myBooksRedirect"
                  >My Books</span
                >
              </li>
            </ul>
          </nav>
          <div class="footer_info desktop-info">
            <a
              href="https://www.rebind.ai/terms-conditions"
              target="_blank"
              class="footer_info-link"
            >
              Terms & Conditions
            </a>
            <a
              href="https://www.rebind.ai/policy"
              target="_blank"
              class="footer_info-link"
            >
              Privacy Policy
            </a>
          </div>
          <p class="body-1 footer_copy mobile-copy">
            © {{ new Date().getFullYear() }} Rebind | Version {{ appVersion }}
          </p>
        </el-col>
      </el-row>
    </el-container>
  </el-footer>
</template>

<script setup>
import MainLogo from '@/components/layout/MainLogo.vue';
import LogoIcon from '@/components/ui/icons/LogoIcon.vue';
import { useUserStore } from '@/store/user';
import { setRedirectPath } from '@/utils/auth';
import { sendAnatylics } from '@/utils/analytics';
import { ANALYTIC_SERVICE } from '@/utils/constants';

const userStore = useUserStore();
const { $auth0 } = useNuxtApp();
const router = useRouter();

const config = useRuntimeConfig();
const appVersion = config.public.appVersion;

/**
 * Redirect to catalogue
 */
const catalogueRedirect = () => {
  if (!userStore.isAuthenticated) {
    sendAnatylics(ANALYTIC_SERVICE.UMAMI, 'login', {
      user_id: 'not authorised',
      redirect: '/#catalogue',
    });
    setRedirectPath('/#catalogue');
    $auth0.loginWithRedirect();
  } else {
    router.push({ path: '/' });
  }
};

const myBooksRedirect = () => {
  router.push({ path: '/my-books' });
};
</script>

<style lang="scss" scoped>
.footer {
  height: auto;
  padding: 32px 0 30px;

  color: white;

  background-color: var(--app-color-gray-100);
  border-top: 1px solid var(--app-color-gray-30);

  &_col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &_logo {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    h3 {
      margin-left: 21px;

      font-family: $libre-caslon;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
    }

    &_svg {
      max-width: 166px;
    }
  }

  &_nav {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.tablet-nav {
      display: none;
    }
  }

  &_link {
    cursor: pointer;

    margin: 0 20px;

    font-family: $libre-caslon;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    color: var(--app-color-gray-0);

    &:hover {
      text-decoration: underline;
    }
  }

  &_text {
    margin-bottom: 64px;
  }

  &_copy {
    color: var(--app-color-gray-70);

    &.mobile-copy {
      display: none;
    }
  }

  &_icon {
    width: 40px;
    height: 40px;
    margin: 0 !important;
    padding: 0 !important;

    border-radius: 50% !important;

    svg {
      margin-top: 3px;
    }
  }

  &_info {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    &.tablet-info {
      display: none;
    }
  }

  &_info-link {
    cursor: pointer;

    display: inline-block;

    font-family: $euclid;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: var(--app-color-gray-70);

    &:first-child {
      margin-right: 40px;

      @media (width <= 1200px) {
        margin-right: 30px;
      }
    }

    &:hover {
      color: var(--app-color-gray-50);
    }
  }
}

@media (width <= 991px) {
  .footer {
    &_info {
      justify-content: flex-start;
      margin-bottom: 16px;

      &.desktop-info {
        display: none;
      }

      &.tablet-info {
        display: flex;
      }
    }

    &_desktopWrap {
      display: none;
    }

    &_nav {
      &.desktop-nav {
        display: none;
      }

      &.tablet-nav {
        display: block;
      }

      ul {
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 18px;
      }
    }

    &_link {
      display: block;
      padding: 0 0 20px;
    }
  }
}

@media (width <= 767px) {
  .footer {
    padding: 32px 14px 20px;

    &_logo {
      justify-content: center;

      h3 {
        margin-left: 12px;
      }
    }

    &_text {
      margin-bottom: 24px;
      font-size: 14px;
      text-align: center;
    }

    &_nav {
      ul {
        align-items: center;
        margin-bottom: 10px;
      }
    }

    &_link {
      font-size: 16px;
    }

    &_info {
      justify-content: center;

      &.desktop-info {
        display: flex;
      }

      &.tablet-info {
        display: none;
      }
    }

    &_copy {
      text-align: center;

      &.mobile-copy {
        display: block;
      }

      &.tablet-copy {
        display: none;
      }
    }
  }
}
</style>
