export const updateReaderColorTheme = (className) => {
  const html = document.documentElement;
  html.removeAttribute('class');
  html.classList.add(className);
};

export const isClickInside = (eventTarget, selector) => {
  if (!eventTarget || !eventTarget.matches || !selector) {
    return false;
  }

  if (eventTarget.matches(selector)) {
    return true;
  }

  if (eventTarget.closest(selector)) {
    return true;
  }

  return false;
};

export const updateRootCssReaderFont = (value) => {
  if (!value) {
    return;
  }
  var root = document.querySelector(':root');
  root.style.setProperty('--reader-selected-font', value);
};

export const getElementChatTextarea = () => {
  return document.getElementById('chat-textarea');
};

export const getElementChatBody = () => {
  return document.getElementById('chat-body');
};
