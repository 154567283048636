import { removeAllExitFromReaderLocations } from '@/utils/localStorage.js';
import { isTouchSupportDevice } from '@/utils/devices.js';

export default defineNuxtPlugin(() => {
  // Run this code when the application starts
  const isTouchDevice = isTouchSupportDevice();

  if (!isTouchDevice) {
    // Remove all exit from reader locations
    removeAllExitFromReaderLocations();
  }
});
